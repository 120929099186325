type IconProps = React.SVGProps<SVGSVGElement>

export default function Sort({ className, ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      className={`fill-current ${className}`}
      {...props}
    >
      <g clipPath="url(#clip0_522_42277)">
        <path d="M44.4 24C46.3882 24 48.0276 25.6207 47.7305 27.5866C47.2057 31.0591 45.9226 34.3893 43.9553 37.3337C41.3181 41.2805 37.5698 44.3566 33.1844 46.1731C28.799 47.9896 23.9734 48.4649 19.3178 47.5388C14.6623 46.6128 10.3859 44.327 7.02944 40.9706C3.67298 37.6141 1.3872 33.3377 0.461153 28.6822C-0.464892 24.0266 0.0103881 19.201 1.82689 14.8156C3.64339 10.4302 6.71953 6.68188 10.6663 4.04473C13.6107 2.07738 16.9409 0.794328 20.4134 0.269487C22.3793 -0.0276419 24 1.61178 24 3.6C24 5.58822 22.3702 7.16143 20.4274 7.58425C18.381 8.02962 16.4254 8.85603 14.6664 10.0313C11.9037 11.8773 9.75038 14.5011 8.47882 17.5709C7.20727 20.6407 6.87458 24.0186 7.52281 27.2775C8.17104 30.5364 9.77108 33.5299 12.1206 35.8794C14.4701 38.2289 17.4636 39.829 20.7225 40.4772C23.9814 41.1254 27.3593 40.7927 30.4291 39.5212C33.4989 38.2496 36.1227 36.0963 37.9687 33.3336C39.144 31.5746 39.9704 29.619 40.4158 27.5726C40.8386 25.6298 42.4118 24 44.4 24Z" fill="#6B00EB" className="fill-inherit" />
      </g>
      <defs>
        <clipPath id="clip0_522_42277">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
