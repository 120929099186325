type IconProps = React.SVGProps<SVGSVGElement>

export default function Calendar({ className, ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={`fill-current ${className}`}
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M5 5C4.44772 5 4 5.44772 4 6V20C4 20.5523 4.44772 21 5 21H19C19.5523 21 20 20.5523 20 20V6C20 5.44772 19.5523 5 19 5H5ZM2 6C2 4.34315 3.34315 3 5 3H19C20.6569 3 22 4.34315 22 6V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V6Z" fill="#79777F" className="fill-inherit" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16 1C16.5523 1 17 1.44772 17 2V6C17 6.55228 16.5523 7 16 7C15.4477 7 15 6.55228 15 6V2C15 1.44772 15.4477 1 16 1Z" fill="#79777F" className="fill-inherit" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8 1C8.55228 1 9 1.44772 9 2V6C9 6.55228 8.55228 7 8 7C7.44772 7 7 6.55228 7 6V2C7 1.44772 7.44772 1 8 1Z" fill="#79777F" className="fill-inherit" />
      <path fillRule="evenodd" clipRule="evenodd" d="M2 10C2 9.44772 2.44772 9 3 9H21C21.5523 9 22 9.44772 22 10C22 10.5523 21.5523 11 21 11H3C2.44772 11 2 10.5523 2 10Z" fill="#79777F" className="fill-inherit" />
      <path d="M14.094 17.9999C14.0472 17.9999 14.005 17.9835 13.9676 17.9507C13.9348 17.918 13.9184 17.8758 13.9184 17.8243V14.3342L12.9072 15.1137C12.865 15.1464 12.8206 15.1581 12.7738 15.1488C12.7316 15.1394 12.6942 15.1137 12.6614 15.0715L12.3103 14.6151C12.2822 14.5729 12.2705 14.5285 12.2752 14.4816C12.2845 14.4348 12.3103 14.3974 12.3524 14.3693L13.9535 13.1333C13.9816 13.1146 14.0074 13.1029 14.0308 13.0982C14.0589 13.0888 14.0893 13.0842 14.1221 13.0842H14.8664C14.9133 13.0842 14.9531 13.1006 14.9858 13.1333C15.0186 13.1661 15.035 13.2082 15.035 13.2597V17.8243C15.035 17.8758 15.0186 17.918 14.9858 17.9507C14.9531 17.9835 14.9133 17.9999 14.8664 17.9999H14.094Z" fill="#79777F" className="fill-inherit" />
      <path d="M8.17556 18C8.12406 18 8.08193 17.9836 8.04916 17.9508C8.01639 17.9181 8 17.8759 8 17.8244V17.4031C8 17.3656 8.00936 17.3165 8.02809 17.2556C8.0515 17.1901 8.10066 17.1269 8.17556 17.066L9.10253 16.1461C9.44429 15.8839 9.72051 15.6615 9.93118 15.4789C10.1465 15.2963 10.3057 15.1301 10.4087 14.9803C10.5117 14.8305 10.5632 14.6854 10.5632 14.5449C10.5632 14.3624 10.5117 14.2125 10.4087 14.0955C10.3104 13.9738 10.1489 13.9129 9.92416 13.9129C9.77434 13.9129 9.64794 13.9457 9.54494 14.0112C9.44663 14.0721 9.36938 14.154 9.3132 14.257C9.25702 14.36 9.21957 14.4724 9.20084 14.5941C9.1868 14.655 9.15637 14.6971 9.10955 14.7205C9.06742 14.7439 9.02294 14.7556 8.97612 14.7556H8.23174C8.18493 14.7556 8.14747 14.7416 8.11938 14.7135C8.09129 14.6807 8.07725 14.6456 8.07725 14.6081C8.08193 14.3928 8.1264 14.1891 8.21067 13.9972C8.29963 13.8052 8.42369 13.6344 8.58287 13.4846C8.74204 13.3347 8.93399 13.2177 9.15871 13.1334C9.38343 13.0445 9.63858 13 9.92416 13C10.3174 13 10.6475 13.0655 10.9143 13.1966C11.1812 13.323 11.3825 13.4986 11.5183 13.7233C11.654 13.948 11.7219 14.2079 11.7219 14.5028C11.7219 14.7275 11.6751 14.9359 11.5815 15.1278C11.4925 15.3198 11.3614 15.5047 11.1882 15.6826C11.015 15.8558 10.809 16.036 10.5702 16.2233L9.74157 17.052H11.6517C11.7032 17.052 11.7453 17.0684 11.7781 17.1011C11.8109 17.1339 11.8272 17.176 11.8272 17.2275V17.8244C11.8272 17.8759 11.8109 17.9181 11.7781 17.9508C11.7453 17.9836 11.7032 18 11.6517 18H8.17556Z" fill="#79777F" className="fill-inherit" />
    </svg>
  )
}
