import {
  createBrowserRouter,
} from 'react-router-dom'

import PageLogin from 'pages/Login'
import PageHome from 'pages/Home'
import PageCalendar from 'pages/Calendar'
import PageError404 from 'pages/Error/404'
import PageTodoList from 'pages/TodoList'
import PageNote from 'pages/Note'
import PageProfile from 'pages/Profile'

const router = createBrowserRouter([
  {
    path: '/',
    Component: PageHome,
  },
  {
    path: '/login',
    Component: PageLogin,
  },
  {
    path: '/app-calendar',
    Component: PageCalendar,
  },
  {
    path: '/app-todo',
    Component: PageTodoList,
  },
  {
    path: '/app-note',
    Component: PageNote,
  },
  {
    path: '/profile',
    Component: PageProfile,
  },
  {
    path: '*',
    Component: PageError404,
  },
])

export default router
