import { useLocation, useNavigate } from 'react-router-dom'

function BottomNavigation() {
  const navigation = useNavigate()
  const location = useLocation()

  const currentParentPath = location.pathname.split('/')[1]

  return (
    <div className="flex gap-2 bg-white shadow-lg border-t-1 border-slate-200 dark:bg-black dark:border-slate-900 ">
      <div
        className={`flex-1 p-6 flex justify-center active:bg-slate-100 cursor-pointer ${currentParentPath === '' ? 'text-primary-600 dark:text-white' : 'text-slate-600 dark:text-slate-400'}`}
        onClick={() => navigation('/')}
        role="presentation"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
        </svg>
      </div>
      <div
        className={`flex-1 p-6 flex justify-center active:bg-slate-100 cursor-pointer ${currentParentPath === 'access-card' ? 'text-primary-600 dark:text-white' : 'text-slate-600 dark:text-slate-400'}`}
        onClick={() => navigation('/access-card')}
        role="presentation"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
        </svg>
      </div>
      <div
        className={`flex-1 p-6 flex justify-center active:bg-slate-100 cursor-pointer ${currentParentPath === 'inquiry' ? 'text-primary-600 dark:text-white' : 'text-slate-600 dark:text-slate-400'}`}
        onClick={() => navigation('/inquiry')}
        role="presentation"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
        </svg>
      </div>
      <div
        className={`flex-1 p-6 flex justify-center active:bg-slate-100 cursor-pointer ${currentParentPath === 'profile' ? 'text-primary-600 dark:text-white' : 'text-slate-600 dark:text-slate-400'}`}
        onClick={() => navigation('/profile')}
        role="presentation"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
        </svg>
      </div>
    </div>
  )
}

export default BottomNavigation
