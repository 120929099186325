import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Filler,
  RadialLinearScale,
} from 'chart.js'
import {
  Bar,
} from 'react-chartjs-2'
import { fakerID_ID as faker } from '@faker-js/faker'
import dayjs from 'dayjs'

import Layout from 'components/Layout'
import Breadcrumb from 'components/Breadcrumb'
import Button from 'components/Button'
import { RELATION } from 'constants/tenant'
import { useNavigate } from 'react-router-dom'

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Filler, RadialLinearScale)

const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
}

const WEEK_NAMES = Array.from(Array(Math.round(dayjs().daysInMonth() / 7)).keys()).map((key) => `Week ${key + 1}`)
const MONTH_NAMES = Array.from(Array(12).keys()).map((key) => dayjs().month(key).format('MMM'))

const CHART_BAR_TOTAL_INQUIRY_MONTH = {
  labels: WEEK_NAMES,
  datasets: [
    {
      fill: true,
      label: 'Total Inquiry',
      data: [100, 102, 90, 80],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
      ],
      borderWidth: 1,
    },
  ],
}

const CHART_BAR_TOTAL_INQUIRY_YEAR = {
  labels: MONTH_NAMES,
  datasets: [
    {
      fill: true,
      label: 'Total Inquiry',
      data: [100, 102, 90, 80, 70, 60, 50, 40, 30, 20, 10, 5],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderWidth: 1,
    },
  ],
}

const INCOMING_ITEM_DATA = Array.from(Array(10).keys()).map((key) => ({
  id: key + 1,
  unit_id: key + 1,
  unit_code: `A/01/${key + 1}`,
  name: faker.person.fullName(),
  requester_name: faker.person.fullName(),
  item_category_id: 1,
  item_category_name: `Kategori Barang ${key + 1}`,
  start_date: '2023-12-31 00:00:00',
  end_date: '2024-12-31 00:00:00',
}))

function Home() {
  const PAGE_NAME = 'Dashboard'
  const navigate = useNavigate()

  const periodMonth = dayjs().startOf('month').format('MMM YYYY')
  return (
    <Layout>
      <Breadcrumb title={PAGE_NAME} />

      <div className="p-4 dark:bg-slate-900 w-[100vw] sm:w-full flex flex-col gap-6">

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="rounded-lg bg-white dark:bg-slate-950 text-slate-600 dark:text-white p-4">
            <div className="flex flex-col">
              <p className="text-sm sm:text-md font-semibold">Perizinan Unit</p>
              <p className="text-xxs sm:text-xs text-slate-500">
                Periode&nbsp;
                <span>{periodMonth}</span>
              </p>
            </div>
            <div className="mt-2">
              <div className="flex justify-between items-center">
                <p className="text-xs sm:text-sm text-slate-500">Izin Kerja</p>
                <p className="text-xs sm:text-sm text-slate-600 font-semibold">99</p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-xs sm:text-sm text-slate-500">Izin Renovasi</p>
                <p className="text-xs sm:text-sm text-slate-600 font-semibold">99</p>
              </div>
            </div>
          </div>

          <div className="rounded-lg bg-white dark:bg-slate-950 text-slate-600 dark:text-white p-4">
            <div className="flex flex-col">
              <p className="text-sm sm:text-md font-semibold">Barang</p>
              <p className="text-xxs sm:text-xs text-slate-500">
                Periode&nbsp;
                <span>{periodMonth}</span>
              </p>
            </div>
            <div className="mt-2">
              <div className="flex justify-between items-center">
                <p className="text-xs sm:text-sm text-slate-500">Permintaan Barang</p>
                <p className="text-xs sm:text-sm text-slate-600 font-semibold">99</p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-xs sm:text-sm text-slate-500">Pembelian Barang</p>
                <p className="text-xs sm:text-sm text-slate-600 font-semibold">99</p>
              </div>
            </div>
          </div>

          <div className="rounded-lg bg-white dark:bg-slate-950 text-slate-600 dark:text-white p-4">
            <div className="flex flex-col">
              <p className="text-sm sm:text-md font-semibold">Perawatan</p>
              <p className="text-xxs sm:text-xs text-slate-500">
                Periode&nbsp;
                <span>{periodMonth}</span>
              </p>
            </div>
            <div className="mt-2">
              <div className="flex justify-between items-center">
                <p className="text-xs sm:text-sm text-slate-500">Perawatan Aset</p>
                <p className="text-xs sm:text-sm text-slate-600 font-semibold">99</p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-xs sm:text-sm text-slate-500">Checklist</p>
                <p className="text-xs sm:text-sm text-slate-600 font-semibold">99</p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-6">

          <div className="p-4 flex flex-col gap-4 bg-white rounded-lg dark:bg-slate-950">
            <p className="text-sm sm:text-md text-slate-600 dark:text-white font-semibold">Total Inquiry Bulan Ini</p>
            <Bar data={CHART_BAR_TOTAL_INQUIRY_MONTH} options={{ ...chartOptions, indexAxis: 'y' as const }} />
          </div>

          <div className="p-4 flex flex-col gap-4 bg-white rounded-lg dark:bg-slate-950">
            <p className="text-sm sm:text-md text-slate-600 dark:text-white font-semibold">Jumlah Inquiry Tahun Ini</p>
            <Bar data={CHART_BAR_TOTAL_INQUIRY_YEAR} options={{ ...chartOptions }} />
          </div>

        </div>

        <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-6">

          <div className="flex flex-col gap-4 bg-white rounded-lg dark:bg-slate-950">
            <div className="px-4 pt-4 flex items-center justify-between">
              <p className="text-sm sm:text-md text-slate-600 dark:text-white font-semibold">Izin Barang Masuk</p>
              <Button size="sm" onClick={() => navigate('/unit-permission/incoming-item')}>Selengkapnya</Button>
            </div>

            <div className="w-full overflow-scroll">
              <table className="border-collapse min-w-full w-max">
                <thead className="font-semibold bg-sky-50 dark:bg-sky-900">
                  <tr>
                    <td className="text-sm text-slate-600 dark:text-white p-4">
                      No. Unit
                    </td>
                    <td className="text-sm text-slate-600 dark:text-white p-4">
                      Nama Pemohon
                    </td>
                    <td className="text-sm text-slate-600 dark:text-white p-4">
                      Jenis Barang
                    </td>
                    <td className="text-sm text-slate-600 dark:text-white p-4">
                      Tanggal Masuk
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {INCOMING_ITEM_DATA.map((data) => (
                    <tr key={data.id} className="border-slate-200 border-b-1 last:border-b-0">
                      <td className="text-sm text-slate-500 dark:text-white px-4 py-2">{data.unit_code}</td>
                      <td className="text-sm text-slate-500 dark:text-white px-4 py-2">{data.requester_name}</td>
                      <td className="text-sm text-slate-500 dark:text-white px-4 py-2">{data.item_category_name}</td>
                      <td className="text-sm text-slate-500 dark:text-white px-4 py-2">{dayjs(data.start_date).format('YYYY-MM-DD')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex flex-col gap-4 bg-white rounded-lg dark:bg-slate-950">
            <div className="px-4 pt-4 flex items-center justify-between">
              <p className="text-sm sm:text-md text-slate-600 dark:text-white font-semibold">Izin Barang Keluar</p>
              <Button size="sm" onClick={() => navigate('/unit-permission/outcoming-item')}>Selengkapnya</Button>
            </div>

            <div className="w-full overflow-scroll">
              <table className="border-collapse min-w-full w-max">
                <thead className="font-semibold bg-sky-50 dark:bg-sky-900">
                  <tr>
                    <td className="text-sm text-slate-600 dark:text-white p-4">
                      No. Unit
                    </td>
                    <td className="text-sm text-slate-600 dark:text-white p-4">
                      Nama Pemohon
                    </td>
                    <td className="text-sm text-slate-600 dark:text-white p-4">
                      Jenis Barang
                    </td>
                    <td className="text-sm text-slate-600 dark:text-white p-4">
                      Tanggal Keluar
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {INCOMING_ITEM_DATA.map((data) => (
                    <tr key={data.id} className="border-slate-200 border-b-1 last:border-b-0">
                      <td className="text-sm text-slate-500 dark:text-white px-4 py-2">{data.unit_code}</td>
                      <td className="text-sm text-slate-500 dark:text-white px-4 py-2">{data.requester_name}</td>
                      <td className="text-sm text-slate-500 dark:text-white px-4 py-2">{data.item_category_name}</td>
                      <td className="text-sm text-slate-500 dark:text-white px-4 py-2">{dayjs(data.start_date).format('YYYY-MM-DD')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Home
